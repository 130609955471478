import React from "react";
import Input from "./Input";
import firebase from "firebase";
import './login.css'
import Logo from '../images/logo.png'



const Login = (props) =>{


  const handleLogin = async (parametros) =>{
    parametros.preventDefault()
    var username = parametros.target.username.value
    var password = parametros.target.password.value
    console.log(username)
    console.log(password)

    firebase
      .auth()
      .signInWithEmailAndPassword(username, password)
      .then(() => {
        const user = firebase.auth().currentUser;
        console.log(user.uid);
        props.initializeGame(user.uid)
      })
      .catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        alert(errorMessage);
        console.log(error)
      });



      // firebase
      // .auth()
      // .createUserWithEmailAndPassword(username, password)
      // .then(() => {
      //   const user = firebase.auth().currentUser;
      //   console.log(user.uid)
      //   props.initializeGame(user.uid)
        
      // })
      // .catch(function(error) {
      //   // var errorCode = error.code;
      //   var errorMessage = error.message;
      //   alert(errorMessage);
      // });

  }

  return (
    <form className="form" onSubmit={handleLogin}>
      <img src={Logo} className="welcome-logo"></img>

      <Input name="username" type="text" placeholder="Username" />
      <Input name="password" type="password" placeholder="Password" />
      <button 
        className="submit"
        type="submit">
          Login
      </button>
    </form>
  );
}

export default Login;
