import React from "react";
import firebase from "firebase";
import Bank from "../../images/bank.png";
import "./cetes-view.css";

class CetesView extends React.Component {
  constructor() {
    super();

    this.state = {
      puedoRetirar: true
    };
  }

  componentDidMount() {
    document.body.style.backgroundColor = "navy";
    this.validarFechaCetes();
  }

  ejecutarInvesion = (valor) => {
    var balance = this.props.balance;

    var fechaCetes = +new Date();

    if (balance >= 100) {
      var newCetes = valor;
      if (this.props.cetes) {
        newCetes = newCetes + this.props.cetes;
      }

      //agrega 100 a tu balance de cetes
      console.log(valor + this.props.cetes);
      firebase
        .database()
        .ref("users/" + this.props.uid + "/cetes")
        .set(newCetes);

      firebase
        .database()
        .ref("users/" + this.props.uid + "/balance")
        .set(balance - valor);

      firebase
        .database()
        .ref("users/" + this.props.uid + "/fechaCetes")
        .set(fechaCetes);

      this.setState({
        puedoRetirar: false
      });
    }
  };

  validarFechaCetes = () => {
    var fechaActual = +new Date();

    var fechaCetes = this.props.fechaCetes;

    var treintaDias = 86400000 * 30;

    var cetes = this.props.cetes;

    var interes = parseInt(cetes * 0.02, 10);

    if (fechaCetes) {
      //comparar que fecha actual sea mayor que 30dias + fechaCetes

      if (fechaActual >= fechaCetes + treintaDias) {
        cetes = cetes + interes;

        firebase
          .database()
          .ref("users/" + this.props.uid + "/fechaCetes")
          .remove();

        firebase
          .database()
          .ref("users/" + this.props.uid + "/cetes")
          .set(cetes);
      } else {
        this.setState({
          puedoRetirar: false
        });
      }
    }
  };

  retirarInversion = () => {
    const newBalance = this.props.balance + this.props.cetes;

    if (newBalance) {
      firebase
        .database()
        .ref("users/" + this.props.uid + "/balance")
        .set(newBalance);

      firebase
        .database()
        .ref("users/" + this.props.uid + "/cetes")
        .remove();
    }
  };

  render() {
    var puedoRetirar = this.state.puedoRetirar;

    if (this.props.cetes === undefined) {
      puedoRetirar = false;
    }

    
    var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    var fecha = new Date(this.props.fechaCetes)


    return (
      <div className="cetes-view">
        <div className="cetes-container">
          <div class="cetes-top">
            <img src={Bank} className="cetes-img"></img>

            <div class="cetes-left">
              <p className="cetes-title">Institución Financiera</p>
              <p className="cetes-text">
                Esta Institución Financiera te dará $2 por cada $100 que
                inviertas con ellos. Pero no podrás sacar tu dinero hasta dentro
                de un mes.
              </p>

              <p className="cetes-text">Tienes ahorrado: ${this.props.cetes}</p>

              {puedoRetirar && (
                <p className="cetes-text">Ya puedes sacar tu dinero</p>
              )}

              {puedoRetirar === false && this.props.cetes && (
                <p className="cetes-text cetes-date">
                  Podrás sacar tu inversión el día: {fecha.toLocaleDateString("es-MX", options)}
                </p>
              )}
            </div>
          </div>
          <div className="cetes-bottom">
            <button
              className="cetes-invertir-btn"
              onClick={() => {
                this.ejecutarInvesion(100);
              }}
            >
              Invierte $100
            </button>
            <button
              className="cetes-invertir-btn"
              onClick={() => {
                this.ejecutarInvesion(200);
              }}
            >
              Invierte $200
            </button>

            <button
              className="cetes-invertir-btn"
              onClick={() => {
                this.ejecutarInvesion(300);
              }}
            >
              Invierte $300
            </button>

            {puedoRetirar && (
              <button
                className="cetes-invertir-btn"
                onClick={() => {
                  this.retirarInversion();
                }}
              >
                Retirar Inversión
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default CetesView;
