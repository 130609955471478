import React from "react";
import "./Header.css";
import Arrow from "./up-arrow.png";
import Piggy from "./piggy-bank.png";

function GameHeader(props) {
  console.log("header props: ");
  console.log(props.name);
  return (
    <header>
      <img
        onClick={() => {
          props.changeView("levels");
        }}
        className="back-btn"
        src={Arrow}
      ></img>
      <div className="balance-container">
        <img className="header-btn" src={Piggy}></img>
        <p className="balance">${props.balance}</p>
      </div>
    </header>
  );
}

export default GameHeader;
