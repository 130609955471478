import React from "react";

const CajaMisteriosa = (props) => {
  return (
    <div
      onClick={() => {
        props.ejecutarRiesgo(props.multiplicador);
      }}
      className="caja-misteriosa"
    >
      <img src={props.img} className="img-misteriosa"></img>
    </div>
  );
};

export default CajaMisteriosa;
