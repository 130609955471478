import React from "react";
import "./tienda.css";
import Donar from "../../images/donation.png";
import Roulette from "../../images/roulette.png";
import Bank from "../../images/bank.png";
import Comprar from "../../images/car.png";

const Tienda = (props) => {
  document.body.style.backgroundColor = "#f0f0f4";
  return (
    <div className="tienda-view">
      <p className="tienda-text">¿Cómo quieres usar tu dinero?</p>

      <div className="tienda-btn-container">
        <div
          onClick={() => {
            props.changeView("donar");
          }}
          className="tienda-btn"
        >
          <img className="tienda-btn-img" src={Donar}></img>
          <p className="tienda-btn-name">Donar</p>
        </div>

        <div
          onClick={() => {
            props.changeView("comprar");
          }}
          className="tienda-btn"
        >
          <img className="tienda-btn-img mirror" src={Comprar}></img>
          <p className="tienda-btn-name">Comprar</p>
        </div>

        <div
          onClick={() => {
            props.changeView("arriesgar");
          }}
          className="tienda-btn"
        >
          <img className="tienda-btn-img mirror" src={Roulette}></img>
          <p className="tienda-btn-name">Arriesgar</p>
        </div>

        <div
          onClick={() => {
            props.changeView("cetes");
          }}
          className="tienda-btn"
        >
          <img className="tienda-btn-img" src={Bank}></img>
          <p className="tienda-btn-name">Institución Financiera</p>
        </div>
      </div>
    </div>
  );
};
export default Tienda;
