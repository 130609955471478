const levels = [
  {
    id: "level1",
    name: "Nivel 1",
    imgURL:
      "https://firebasestorage.googleapis.com/v0/b/robin-learn.appspot.com/o/website-assets%2Frestaurant.png?alt=media&token=2e1f4141-dd91-4284-88c1-46c56d91ab51"
  },
  {
    id: "level2",
    name: "Nivel 2",
    imgURL:
      "https://img2.freepng.es/20191221/jae/transparent-order-icon-shop-icon-logistic-icon-5dfec2d9aa40b5.6783748915769771136974.jpg"
  },
  {
    id: "level3",
    name: "Nivel 3",
    imgURL:
      "https://img2.freepng.es/20191221/jae/transparent-order-icon-shop-icon-logistic-icon-5dfec2d9aa40b5.6783748915769771136974.jpg"
  },
  {
    id: "level4",
    name: "Nivel 4",
    imgURL:
      "https://img2.freepng.es/20191221/jae/transparent-order-icon-shop-icon-logistic-icon-5dfec2d9aa40b5.6783748915769771136974.jpg"
  },
  {
    id: "level5",
    name: "Nivel 5",
    imgURL:
      "https://img2.freepng.es/20191221/jae/transparent-order-icon-shop-icon-logistic-icon-5dfec2d9aa40b5.6783748915769771136974.jpg"
  },
  {
    id: "level6",
    name: "Nivel 6",
    imgURL:
      "https://firebasestorage.googleapis.com/v0/b/robin-learn.appspot.com/o/website-assets%2Frestaurant.png?alt=media&token=2e1f4141-dd91-4284-88c1-46c56d91ab51"
  },
  {
    id: "level7",
    name: "Nivel 7",
    imgURL:
      "https://img2.freepng.es/20191221/jae/transparent-order-icon-shop-icon-logistic-icon-5dfec2d9aa40b5.6783748915769771136974.jpg"
  },
  {
    id: "level8",
    name: "Nivel 8",
    imgURL:
      "https://img2.freepng.es/20191221/jae/transparent-order-icon-shop-icon-logistic-icon-5dfec2d9aa40b5.6783748915769771136974.jpg"
  },
  {
    id: "level9",
    name: "Nivel 9",
    imgURL:
      "https://img2.freepng.es/20191221/jae/transparent-order-icon-shop-icon-logistic-icon-5dfec2d9aa40b5.6783748915769771136974.jpg"
  },
  {
    id: "level10",
    name: "Nivel 10",
    imgURL:
      "https://img2.freepng.es/20191221/jae/transparent-order-icon-shop-icon-logistic-icon-5dfec2d9aa40b5.6783748915769771136974.jpg"
  }
];

export default levels;
