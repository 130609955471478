import React from "react";
import './pago.css'

const Pago = (props) =>{
    return(
        <div className="paywall">
            <p>Para acceder a este contenido debes adquirir la versión de paga.</p>

            <button className="pay-btn" onClick={()=>{window.open("https://buy.stripe.com/00g5n30bU5JK49ydQX", "_blank")}}>
                Adquirir
            </button>
        </div>
    )
}
export default Pago