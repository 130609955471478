import React from "react";
import Card from "./Card";
import Header from "./Header";
// import Footer from "./Footer";
import levels from "../levels";
import game from "../robin-game";
import preguntas from "../texto-preguntas";
import progress from "../progress";
import GameHeader from "./gameHeader";
import AnswerCard from "./AnswerCard";
import Tienda from "./tienda/tienda";
import GameView from "./game/game";
import Register from "./Register";
import Login from "./Login";
import Bienvenido from "./Bienvenido";
import firebase from "firebase";
import Donaciones from "./donaciones/donaciones";
import Compras from "./compras/compras";
import Arriesgar from "./arriesgar/arriesgar";
import CetesView from "./cetes/cetes-view";
import Pago from "./pago";

// import Login from "./Login";


class App extends React.Component {
  constructor() {
    super();

    this.state = {
      view: "welcome",
      balance: 100
    };

    const firebaseConfig = {
      apiKey: "AIzaSyBi0XS0B9rVJeiswWeQQJ0qy8ie2sXnfxo",
      authDomain: "afortunada-12f54.firebaseapp.com",
      projectId: "afortunada-12f54",
      storageBucket: "afortunada-12f54.appspot.com",
      messagingSenderId: "472401692298",
      appId: "1:472401692298:web:d980d0d564910273cb4f73",
      measurementId: "G-GMXRHD4LVR"
    };

    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }
  }

  componentWillMount() {
    firebase
      .database()
      .ref("game-content")
      .once("value", (snapshot) => {
        this.setState({
          gameContent: snapshot.val()
        });
      });
  }

  changeView = (newView) => {
    // this.state.view = newView

    // if(newView==="pago"){
    //   window.open("https://www.paypal.com/paypalme/robinbootcamp", "_blank")
    // }
    // else{
      this.setState({
        view: newView,
        currentAnswers: game.q1,
        currentQuestion: "q1"
      });
    // }

    
  };

  handleGameStart = (level) => {
    this.setState({
      view: "game",
      selectedLevel: this.state.gameContent[level]
    });
  };

  changeQuestion = (newQuestion) => {
    this.setState({
      currentAnswers: game[newQuestion],
      currentQuestion: newQuestion
    });

    alert(newQuestion);
  };

  initializeGame = (uid) => {
    // this.state.view = newView
    firebase
      .database()
      .ref("users/" + uid)
      .on("value", (snap) => {
        this.setState({
          user: snap.val()
        });
      });
    this.setState({
      view: "levels",
      uid: uid
    });
  };

  render() {
    if (this.state.view === "levels" && this.state.user) {
      return (
        <div>
          <Header
            key={64654}
            img={"https://jaosidjfasd"}
            available={true}
            changeView={this.changeView}
            balance={this.state.user.balance}
          />

          <div className="level-select-view">
            {levels.map((value, index) => {
              return (
                <Card
                  key={value.id}
                  name={value.name}
                  img={value.imgURL}
                  id={value.id}
                  available={progress[index]}
                  changeView={this.changeView}
                  handleGameStart={this.handleGameStart}
                />
              );
            })}

            {/* Esto es igual al codigo de la linea 17 a la 25 */}
            {/* {levels.map(createCard)} */}
          </div>
        </div>
      );
    } else if (this.state.view === "game") {
      return (
        // <div>
        //   <GameHeader
        //     key={64654}
        //     img={"https://jaosidjfasd"}
        //     available={true}
        //     changeView={this.changeView}
        //   />

        //   <p>{preguntas[this.state.currentQuestion]}</p>

        //   <div className="level-select-view">
        //     {this.state.currentAnswers.map((value, index) => {
        //       return (
        //         <AnswerCard
        //           key={value.id}
        //           name={value.name}
        //           nextQuestion={value.nextQuestion}
        //           changeQuestion={this.changeQuestion}
        //         />
        //       );
        //     })}
        //   </div>
        // </div>
        <GameView
          currentQuestion={this.state.currentQuestion}
          currentAnswers={this.state.currentAnswers}
          changeView={this.changeView}
          balance={this.state.balance}
          selectedLevel={this.state.selectedLevel}
          uid={this.state.uid}
          user={this.state.user}
        />
      );
    } else if (this.state.view === "logros") {
      return <div>vista de logros</div>;
    } else if (this.state.view === "tienda") {
      return (
        <div>
          <Header
            key={64654}
            img={"https://jaosidjfasd"}
            available={true}
            changeView={this.changeView}
            balance={this.state.user.balance}
            backView={"levels"}
          />
          <Tienda changeView={this.changeView} />
        </div>
      );
    } else if (this.state.view === "welcome") {
      return (
        <div>
          <Bienvenido changeView={this.changeView} />
        </div>
      );
    } else if (this.state.view === "register") {
      return (
        <div>
          <Register initializeGame={this.initializeGame} />
        </div>
      );
    } else if (this.state.view === "login") {
      return (
        <div>
          <Login initializeGame={this.initializeGame} />
        </div>
      );
    } else if (this.state.view === "donar") {
      return (
        <div>
          <Header
            key={64654}
            img={"https://jaosidjfasd"}
            available={true}
            changeView={this.changeView}
            balance={this.state.user.balance}
            backView={"tienda"}
          />
          <Pago/>
          {/* <Donaciones uid={this.state.uid} /> */}
        </div>
      );
    } else if (this.state.view === "comprar") {
      return (
        <div>
          <Header
            key={64654}
            img={"https://jaosidjfasd"}
            available={true}
            changeView={this.changeView}
            balance={this.state.user.balance}
            backView={"tienda"}
          />

          <Pago/>
          {/* <Compras uid={this.state.uid} /> */}
        </div>
      );
    } else if (this.state.view === "arriesgar") {
      return (
        <div>
          <Header
            key={64654}
            available={true}
            changeView={this.changeView}
            balance={this.state.user.balance}
            backView={"tienda"}
          />
          <Arriesgar changeView={this.changeView} balance={this.state.user.balance} uid={this.state.uid} />
        </div>
      );
    } else if (this.state.view === "cetes") {
      return (
        <div>
          <Header
            key={64654}
            available={true}
            changeView={this.changeView}
            balance={this.state.user.balance}
            backView={"tienda"}
          />
          <CetesView
            cetes={this.state.user.cetes}
            balance={this.state.user.balance}
            uid={this.state.uid}
            fechaCetes={this.state.user.fechaCetes}
          />
        </div>
      );
    
    } 
    else if (this.state.view === "pago") {
      return (
        <div>
          <Header
            key={64654}
            available={true}
            changeView={this.changeView}
            balance={this.state.user.balance}
            backView={"levels"}
          />
          <Pago/>
        </div>
      );
    }
    else {
      return <div>Loading...</div>;
    }
  }
}

export default App;
