import React from "react";
import Input from "./Input";
import firebase from "firebase";
import "./login.css";
import Logo from '../images/logo.png'


const Login = (props) => {
  const handleRegister = async (parametros) => {
    parametros.preventDefault();
    var username = parametros.target.username.value;
    var password = parametros.target.password.value;
    var passwordConfirm = parametros.target.passwordConfirm.value;
    console.log(username);
    console.log(password);
    console.log(passwordConfirm);

    //codigo de comparacionhandleSubmit = () => {
    // perform all neccassary validations
    if (password !== passwordConfirm) {
      alert("Passwords don't match");
    } else {
      firebase
        .auth()
        .createUserWithEmailAndPassword(username, password)
        .then(() => {
          const user = firebase.auth().currentUser;
          console.log(user.uid);

          var data = {
            balance: 0,
            nombre: "Nombre"
          };

          firebase
            .database()
            .ref("users/" + user.uid)
            .set(data);
          props.initializeGame(user.uid);
        })
        .catch(function (error) {
          // var errorCode = error.code;
          var errorMessage = error.message;
          alert(errorMessage);
        }); // make API call
    }

    //codigo de crear cuenta
  };

  return (
    <form className="form" onSubmit={handleRegister}>
      <img src={Logo} className="welcome-logo"></img>

      <Input name="username" type="text" placeholder="Email" />
      <Input name="password" type="password" placeholder="Password" />

      <Input
        name="passwordConfirm"
        type="password"
        placeholder="Confirmar Password"
      />

      {/* <p>Bienvenido al juego!</p>
      <button className="submit" type="submit">Login</button> */}

      <button className="submit" type="submit">
        Registrar
      </button>
    </form>
  );
};

export default Login;
