import React from "react";
import "./Header.css";
import Shop from "./shop.png";
import Medal from "./medal.png";
import Piggy from "./piggy-bank.png";
import Arrow from "./up-arrow.png";
import Logo from '../images/logo.png'

function Header(props) {
  
  return (
    <header>
      {props.backView && (
        <div
          onClick={() => {
            props.changeView(props.backView);
          }}
        >
          <img className="back-btn" src={Arrow}></img>
        </div>
      )}

      {!props.backView && 
      <img src={Logo} className="header-logo"></img>
      }
      <div className="right-header">
        {/* <img
          onClick={() => {
            props.changeView("logros");
          }}
          src={Medal}
          className="header-btn"
        ></img> */}

        <img
          onClick={() => {
            props.changeView("tienda");
          }}
          src={Shop}
          className="header-btn"
        ></img>

        <div className="balance-container">
          <img className="header-btn" src={Piggy}></img>
          <p className="balance">${props.balance}</p>
        </div>
      </div>
    </header>
  );
}

export default Header;
