import React from "react";

const CompraDonacion = (props) => {
  return (
    <div className="donacion-box">
      <img className="donacion-img" src={props.data.img}></img>

      <p className="donacion-nombre">{props.data.nombre}</p>
      <p className="donacion-descripcion">{props.data.descripcion}</p>
      <button
        className="donacion-compra-btn"
        onClick={() => {
          props.pagarDonacion(props.id);
        }}
      >
        ${props.data.costo}
      </button>
    </div>
  );
};
export default CompraDonacion;
