import React from "react";
import firebase from "firebase";
import CompraCompra from "./compra-compra";
import DonacionObtenida from "../donaciones/donacion-obtenida";
import "../donaciones/donaciones.css";

class Compras extends React.Component {
  constructor() {
    super();

    this.state = {
      owned: {}
    };
  }

  componentDidMount() {
    document.body.style.backgroundColor = "navy";
    firebase
      .database()
      .ref("compras")
      .once("value", (snap) => {
        this.setState({
          catalogo: snap.val()
        });
      });

    firebase
      .database()
      .ref("logros/" + this.props.uid)
      .on("value", (snap) => {
        if (snap.val()) {
          this.setState({
            owned: snap.val()
          });
        }
      });
  }

  pagarDonacion = (id) => {
    console.log(id);
    firebase
      .database()
      .ref("users/" + this.props.uid + "/balance")
      .once("value")
      .then((snap) => {
        let balance = snap.val();

        if (balance >= this.state.catalogo[id].costo) {
          let newBalance = balance - this.state.catalogo[id].costo;

          firebase
            .database()
            .ref("users/" + this.props.uid)
            .update({ balance: newBalance });

          firebase
            .database()
            .ref("logros/" + this.props.uid + "/" + id)
            .set(true);
          alert("Gracias por comprar looser");
        } else {
          alert("No tienes suficiente dinero aún.");
        }
      });
  };

  render() {
    if (this.state.catalogo) {
      let catalogo = this.state.catalogo;
      let owned = this.state.owned;

      console.log(owned);
      return (
        <div class="catalogo-donaciones">
          {Object.keys(catalogo).map((key) => {
            if (owned[key]) {
              return (
                <div>
                  <DonacionObtenida data={catalogo[key]} />
                </div>
              );
            }
            return (
              <div>
                <CompraCompra
                  data={catalogo[key]}
                  pagarDonacion={this.pagarDonacion}
                  id={key}
                />
              </div>
            );
          })}
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default Compras;
