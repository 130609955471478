import React from "react";
import "./card.css";
import Building from "../images/restaurant.png";
import Lock from "./lock.png";

function Card(props) {
  if (props.available) {
    return (
      <div className="level-card">
        <h2 className="level-name">{props.name}</h2>
        <img className="level-img" src={Building} alt="avatar_img" />
        <button
          onClick={() => {
            props.handleGameStart(props.id);
          }}
          className="level-btn"
        >
          Jugar
        </button>
      </div>
    );
  } else {
    return (
      <div className="level-card-unavailable">
        <h2 className="level-name">{props.name}</h2>
        <img className="level-img" src={Lock} alt="avatar_img" />
        <button
          onClick={() => {
            props.changeView("pago");
          }}
          className="level-btn-rojo"
        >
          Comprar
        </button>
      </div>
    );
  }
}

export default Card;
