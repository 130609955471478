import React from "react";
import "./answer-card.css";

function AnswerCard(props) {
  return (
    <div
      onClick={() => {
        props.changeQuestion(props.nextQuestion, props.reward);
      }}
      className="answer-card"
    >
      <p className="answer-name">{props.name}</p>
    </div>
  );
}

export default AnswerCard;
