import React from "react";
import firebase from "firebase";
import CajaMisteriosa from "./caja-misteriosa";
import Block from "../../images/block.png";

import "./arriesgar.css";

class Arriesgar extends React.Component {
  constructor() {
    super();

    this.state = {};
  }

  shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }

  ejecutarRiesgo = (multiplicador) => {
    var balance = this.props.balance;

    if (balance >= 10) {
      let newBalance = balance + 10 * multiplicador - 10;

      firebase
        .database()
        .ref("users/" + this.props.uid)
        .update({ balance: newBalance });

      alert(
        "Compaste una caja por $10 y la caja contenía $" + multiplicador * 10
      );

      this.props.changeView("tienda")
    }
  };

  render() {
    var multiplicadores = [2, 0.5, 0];

    this.shuffleArray(multiplicadores);

    return (
      <div className="arriesgar-view">
        <p>
          Puedes comprar una caja misteriosa por $10. Estas cajas pueden
          contener $0, $5 o $20.
        </p>

        <div className="contenedor-arriesgar">
          <CajaMisteriosa
            key={""}
            multiplicador={multiplicadores[0]}
            ejecutarRiesgo={this.ejecutarRiesgo}
            img={Block}
          />
          <CajaMisteriosa
            key={""}
            multiplicador={multiplicadores[1]}
            ejecutarRiesgo={this.ejecutarRiesgo}
            img={Block}
          />
          <CajaMisteriosa
            key={""}
            multiplicador={multiplicadores[2]}
            ejecutarRiesgo={this.ejecutarRiesgo}
            img={Block}
          />
        </div>
      </div>
    );
  }
}

export default Arriesgar;
