const game = {
  q1: [
    {
      name: "reso 1",
      nextQuestion: "q2"
    },
    {
      name: "Respuesta 2",
      nextQuestion: "q3"
    },
    {
      name: "Respuesta 3",
      nextQuestion: "q2"
    },
    {
      name: "Respuesta 4",
      nextQuestion: "q4"
    }
  ],

  q2: [
    {
      name: "Chida 1",
      nextQuestion: "final"
    },
    {
      name: "Respuesta 2",
      nextQuestion: "final"
    }
  ],
  nextQuestion: "caca"
};

export default game;
