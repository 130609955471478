import React from "react";
import GameHeader from "../gameHeader";
import AnswerCard from "../AnswerCard";
import "../game-view.css";

import firebase from "firebase";

class GameView extends React.Component {
  constructor() {
    super();

    this.state = {
      currentScene: "scene1"
    };
  }

  componentDidMount() {
    firebase
      .database()
      .ref("users/" + this.props.uid + "/balance")
      .once("value", (snap) => {
        this.setState({
          balance: snap.val()
        });

        console.log(snap.val());
      });
  }

  changeQuestion = (newScene) => {
    this.setState({
      currentScene: newScene
    });
  };

  handleFinish = (reward) => {
    const newBalance = this.state.balance + reward;

    if(this.state.balance>0){
      alert("Gracias por volver a jugar, ya obtuviste todas las monedas de este nivel :)")
      this.props.changeView("levels")
    }

    else{
      firebase
      .database()
      .ref("users/" + this.props.uid + "/balance")
      .set(newBalance);
    
    alert("Gracias por jugar, ya agregamos las monedas a tu balance :)")
    this.props.changeView("levels")
    }
    
  };

  render() {
    var scene = this.props.selectedLevel[this.state.currentScene];

    console.log(this.props.selectedLevel);
    var options = scene.options;

    return (
      <div className="game-view">
        <GameHeader
          key={64654}
          img={"https://jaosidjfasd"}
          available={true}
          changeView={this.props.changeView}
          balance={this.props.user.balance}
        />

        <p className="texto-pregunta">{scene.text}</p>

        <div className="answer-container">
          {Object.keys(options).map((value) => {
            if (value === "finish") {
              return (
                <div
                  onClick={() => {
                    this.handleFinish(scene.reward);
                  }}
                  className="answer-card"
                >
                  <p className="answer-name">{options[value]}</p>
                </div>
              );
            } else {
              return (
                <AnswerCard
                  key={value.id}
                  name={options[value]}
                  nextQuestion={value}
                  changeQuestion={this.changeQuestion}
                />
              );
            }
          })}

          {/* {props.currentAnswers.map((value, index) => {
            return (
              <AnswerCard
                key={value.id}
                name={value.name}
                nextQuestion={value.nextQuestion}
                changeQuestion={this.changeQuestion}
              />
            );
          })} */}
        </div>
      </div>
    );
  }
}

export default GameView;
