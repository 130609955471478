import React from "react";

const DonacionObtenida = (props) => {
  return (
    <div className="donacion-box">
      <img className="donacion-img" src={props.data.img}></img>
      <p className="donacion-nombre">{props.data.nombre}</p>
      <p className="donacion-descripcion">{props.data.descripcion}</p>
      {/* <p> Ya compraste el articulo </p> */}
    </div>
  );
};
export default DonacionObtenida;
