import React from "react";
import Input from "./Input";
import firebase from "firebase";
import "./login.css";
import Logo from '../images/logo.png'

const Bienvenido = (props) => {
  return (
    <div className="welcome-view">
      <img src={Logo} className="welcome-logo"></img>
      <p> ¿Quieres Iniciar Sesión o Registrarte? </p>
      <button
        className="submit"
        type="submit"
        onClick={() => {
          props.changeView("register");
        }}
      >
        Registrarme
      </button>
      <button
        className="submit"
        type="submit"
        onClick={() => {
          props.changeView("login");
        }}
      >
        Iniciar
      </button>
    </div>
  );
};

export default Bienvenido;
